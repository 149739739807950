
























import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class LoginLogin extends Vue {
  public form_list: MyForm.formList[] = [];

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    if (form.ValidateAll()) {
      this.login(this.$api.getFormData([this.form_list]));
    }
  }
  // 登录
  login(data) {
    let _this = this;
    this.$api.request({
      url: "user/login/register",
      data,
      success(res) {
        _this.$api.setSession("token", res.data.access_token);
        _this.$api.href("/");
      },
    });
  }
  // 忘记密码
  forget() {
    this.$api.href("/login/forget");
  }

  init() {
    // this.$api.href("/maintain");
    this.form_list = [
      {
        name: "mobile",
        value: "",
        label: "手机号码",
        type: "input",
        input_type: "number",
        placeholder: "请输入您的手机号码",
        required: true,
        validate: "phone",
      },
      {
        name: "password",
        value: "",
        label: "密    码",
        type: "password",
        mask: true,
        placeholder: "请输入推荐人手机号码",
        required: true,
        validate_min: 6,
        validate_max: 6,
        label_center: true,
      },
    ];
    this.$api.refreshForm([this.$refs.form]);
  }
}
